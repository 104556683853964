img {
    // Ancho de imágenes entre 12 y 60px
    @for $size from 1 through 5 {
        &.w-#{$size*12} {
            width: $size*12px;
        }
    }

    // Altura fija para que todas las imágenes sean de igual altura
    &.fixed-height {
        height: 320px;
    }

    // Imagen de altura limitada en una ventana modal para evitar
    // que la modal sea demasiado larga en pantallas pequeñas
    &.modal-img-fluid {
        width: 100%;
        max-height: 250px;
    }
}
