/**
 * Estilos del Paginador
 *
 * El paginador se usa para la numeración de las páginas de resultados, por ejemplo, de una tabla: < 1 2 3 4 ... >
 *
 *
 */
.pagination {
    justify-content: center !important;
}
