/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    text-align: center;
    margin-bottom: 20px;
    padding: 30px 20px;
    background: #fff;
}

.team .member img {
    max-width: 100%;
    margin: 0 0 30px 0;
}

.team .member h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
}

.team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
}

.team .member p {
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: #aaaaaa;
}

.team .member .social {
    margin-top: 15px;
}

.team .member .social a {
    color: #919191;
    transition: 0.3s;
}

.team .member .social a:hover {
    color: #16df7e;
}

.team .member .social i {
    font-size: 18px;
    margin: 0 2px;
}
