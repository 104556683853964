/**
 * Etiqueta con el nombre del elemento representado en el mapa
 */
.map-label {
    color: #111;
    font: {
        size: 12px;
        weight: bold;
    }
    background: rgba(235, 235, 235, 0.81);
    white-space: nowrap;
    border: 4px solid  rgba(0,0,0,0.25);
    padding: 1px 6px;
    border-radius: 4px;
    pointer-events: none;
    user-select: none;
    display: block;
    position: absolute;
    left: 24px;
    top: -3px;
}