.btn {
    &.btn-squared {
        font-size: 80%;
        border-radius: 0;
        letter-spacing: 0.3rem;
        font-weight: bold;
        padding: 1rem;
        transition: all 0.2s;
        text-transform: uppercase;
    }

    &.btn-main {
        color: #fff;
        background: #16507b;
    }

    &.btn-pink {
        color: #fffffffa;
        background: #e2185b;
        font: {
            weight: 600;
        }

        &:hover {
            background: #5f0523;
            color: #fffffffa;
        }
    }

    &.btn-outline-pink {
        color: #e2185b;
        border: 1px solid #e2185b;
        font: {
            weight: 600;
        }

        &:hover {
            background: #e2185b;
            color: #fffffffa;
        }
    }

    &.btn-small {
        font: {
            size: 80%;
        }
    }
}

.input-group-append {
    .btn {
        border-radius: 0;
    }
}