/**
 * Componente Vue Select
 * 
 * Vue Select es un componente de selección / desplegable / escritura.
 * Proporciona una plantilla predeterminada que se adapta a la mayoría de los casos de uso para 
 * un menú desplegable de selección filtrable.
 * El componente está diseñado para ser lo más liviano posible, al tiempo que mantiene altos estándares de accesibilidad, 
 * experiencia de desarrollador y personalización.
 *
 * <v-select>...</v-select>
 *
 * @link https://vue-select.org/
 */

 /**
  * Incrementa la altura del selector
  */
.vs__selected-options {
    padding-top: 2px !important;
    padding-bottom: 2px !important; 
}

.vs__search {
  margin: 10px !important;
}