[v-cloak] {
    display: block;
    padding: 50px 0;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

[v-cloak]:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner 0.6s linear infinite;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

[v-cloak]>* {
    display: none;
}