/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 700;
    font-size: 32px;
    color: #5f687b;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-bottom: 10px;
}

.about .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #16df7e;
}

.about .content p:last-child {
    margin-bottom: 0;
}
