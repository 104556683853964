/**
 * Efecto zoom al situarse sobre una imagen
 *
 * @example
 *
 * <div class="zoomin">
 *     <img src="/image.png" alt="" />
 * </div>
 */
.zoomin {
    overflow: hidden;

    img {
        object-fit: fill;
        transition: transform 2s, filter 1.5s ease-in-out;
        transform-origin: center center;
        filter: brightness(80%);

        &:hover {
            filter: brightness(100%);
            transform: scale(1.3);
        }
    }
}