/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    border: 1px solid #fff;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
}

.services .icon-box .icon {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
}

.services .icon-box .icon i {
    color: #16df7e;
    font-size: 36px;
    transition: 0.3s;
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #5f687b;
    transition: ease-in-out 0.3s;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    border-color: #eaecef;
}

.services .icon-box:hover h4 a {
    color: #16df7e;
}
