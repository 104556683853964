.flag {
    .flag-icon {
        display: inline-block;
        width: 48px;
        height: 32px;

        /**
        * Los idiomas disponibles
        */
        $langs: 'ca', 'es', 'eu', 'fr', 'gl', 'pt';

        // Una clase para cada idioma disponible
        // @example ".flag-icon es"
        @each $lang in $langs {
            &.#{$lang} {
                background: {
                    size: cover;
                    image: url(/assets/images/icons/flags/#{$lang}.png);
                }
            }
        }

        &.selected {
            opacity: 0.6;
        }
    }

    .flag-name {
        position: relative;
        top: -10px;
        margin-left: 1rem;
    }
}
