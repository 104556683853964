/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services {
    padding: 0;
}

.featured-services .icon-box {
    padding: 20px;
    color: #5f687b;
    background: #f9f9fa;
    border-radius: 4px;
    border: 1px solid #f9f9fa;
    transition: 0.3s;
    height: 100%;
}

.featured-services .icon {
    margin: 0 0 15px 0;
}

.featured-services .icon i {
    color: #16df7e;
    font-size: 36px;
}

.featured-services .title {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 18px;
}

.featured-services .title a {
    color: #5f687b;
    transition: 0.3s;
}

.featured-services .icon-box:hover {
    border-color: #eaecef;
}

.featured-services .icon-box:hover .title a {
    color: #16df7e;
}

.featured-services .description {
    line-height: 24px;
    font-size: 14px;
}
