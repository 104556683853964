/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 80vh;
    background: #fff;
    padding-top: 82px;
}

#hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #3e4450;
}

#hero h2 {
    color: #858ea1;
    margin: 10px 0 30px 0;
    font-size: 24px;
}

#hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #16df7e;
    box-shadow: 0 8px 28px rgba(22, 223, 126, 0.45);
}

#hero .btn-get-started:hover {
    background: #206bfb;
    box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}

#hero .btn-watch-video {
    font-size: 16px;
    display: inline-block;
    transition: 0.5s;
    margin-left: 25px;
    color: #5f687b;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

#hero .btn-watch-video i {
    color: #949cac;
    font-size: 32px;
    line-height: 0;
    margin-right: 8px;
}

#hero .btn-watch-video:hover i {
    color: #16df7e;
}

#hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
    #hero {
        height: 100vh;
    }
    #hero .animated {
        -webkit-animation: none;
        animation: none;
    }
    #hero .hero-img {
        text-align: center;
    }
    #hero .hero-img img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    #hero {
        height: auto;
    }
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }
    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
    #hero .hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #hero .hero-img img {
        width: 80%;
    }
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
