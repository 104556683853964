.navbar {
    
    .search-box {
        max-width: 225px;
    }

    &.navbar-mobile {
        .input-group {
            max-width: calc(100% - 18px);
            .search-box {
                max-width: 100%;
            }
        }
    }
}