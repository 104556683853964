/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #2487ce;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;

    i {
        font-size: 28px;
        color: #fff;
        line-height: 0;
    }

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &:hover {
        background: #3e9bdd;
        color: #fff;
    }
}
