table {

    /**
     * Estilo de las filas impares de las tablas marcadas con
     * La clase "striped"
     */
    &.table-striped > tbody > tr:nth-of-type(2n+1) {
        background: #d9e5f0 !important;
    }

    /**
     * Ocultación de columnas de la tabla para tamaños de pantalla pequeños
     */
    .column-s-hidden {
        @media(max-width: 1024px) {
            display: none;
        }
    }
    
    .column-xs-hidden {
        @media(max-width: 640px) {
            display: none;
        }
    }
}