.text-main {
    color: #16507b;
}

.text-pink {
    color: #e2185b;
}

.text-align-fa-2x {
    position: relative;
    top: -6px;
    margin-left: 5px;
}

.text-small {
    font: {
        size: 12px !important;
    }
}

/**
 * Contenido editable
 */
[contenteditable="true"] {
    background-color: #f4f49b;
    padding: 1rem;
}
