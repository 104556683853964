
/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    background: #2487ce;
}
  
/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    height: 24px;
    width: 24px;
    background: #2487ce;
}
  
/* All the same stuff for IE */
input[type=range]::-ms-thumb {
    height: 24px;
    width: 24px;
    background: #2487ce;
}