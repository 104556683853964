/**
 * Elimina el mesanje de notificación de registro
 */
.tox-notifications-container{
    display: none !important;
}

/**
 * La barra de herramientas
 */
.tox-tinymce{
    z-index:1039 !important;
}