.required {
    label {
        &:after {
            color: red;
            content: '*';
            margin-left: 0.25rem;
        }
    }
}

label {
    &.required {
        &:after {
            color: red;
            content: '*';
            margin-left: 0.25rem;
        }
    }
}

