/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
    
    .header-scrolled {
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        padding: 12px 0;
    }

    .logo {
        margin: 0;
        padding: 0;
        line-height: 1;
        width: 270px;
        position: relative;
        top: 4px;

        a {
            color: #5f687b;
        }

        img {
            max-height: 48px;
            position: relative;
            top: -4px;
        }

        .text {
            font: {
                size: 32px;
                weight: 700;
            }
            letter-spacing: 0.5px;
        }
    }

    .language {
        .fas {
            font: {
                size: 24px;
            }
            margin-right: 8px;
        }

        .disabled {
            color: grey !important;
            pointer-events: none;
            
            img {
               opacity: 0.7;
            }
        }
    }

    @media (max-width: 991px) {
        padding: 12px 0;
    }
}
