/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    color: #444444;
    font-size: 14px;
    background: #f8fbfe;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
    margin-bottom: 5px;

    /**
     * Top
     */
    .footer-top {
        padding: 10px 0 30px 0;

        .footer-item {
            margin-bottom: 30px;

            h3 {
                font-size: 26px;
                margin: 0 0 10px 0;
                padding: 2px 0 2px 0;
                line-height: 1;
                font-weight: 300;
            }

            p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                font-family: 'Raleway', sans-serif;
                color: #777777;
            }
        }
      
        h4 {
            font-size: 16px;
            font-weight: bold;
            color: #124265;
            position: relative;
            padding-bottom: 12px;
        }

        .stats {
            .stat {
                margin-bottom: 5px;
        
                .text {
                    position: relative;
                    top: -4px;
                    margin: 0 5px 5px 0;
                    color: rgb(121, 117, 117);
                    font: {
                        size: 20px;
                        weight: 1000;
                    }
                }

                &.stat-toggle {
                    @media (max-width: 480px) {
                        display: none;
                    }
                }
            }
        }

        .footer-links {
            margin-bottom: 30px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                i {
                    padding-right: 2px;
                    color: #2487ce;
                    font-size: 18px;
                    line-height: 1;
                }

                li {
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                }

                li:first-child {
                    padding-top: 0;
                }

                a {
                    color: #777777;
                    transition: 0.3s;
                    display: inline-block;
                    line-height: 1;
                }

                a:hover {
                    text-decoration: none;
                    color: #2487ce;
                }
            }
        }

    }

    /**
     * Donaciones
     */
    .footer-donations {
        font-size: 15px;

        h4 {
            font-size: 16px;
            font-weight: bold;
            color: #444444;
            position: relative;
            padding-bottom: 12px;
        }

        form {
            margin-top: 10px;
            position: relative;
            text-align: left;
        }

        form input[type="submit"] {
            width: 100%;
            border: none;
            font-size: 20px;
            padding: 10px;
            background: #2487ce;
            color: #fff;
            transition: 0.3s;
            border-radius: 0;
        }

        form input[type="submit"]:hover {
            background: #3194db;
        }

        .fa-btc {
            margin-right: 3px;
            font: {
                size: 30px;
            }
        }
    }

    /**
     * Redes Sociales
     */
    .social-links {
        
        a {
            font-size: 18px;
            display: inline-block;
            background: #2487ce;
            color: #fff;
            line-height: 1;
            padding: 8px 0;
            margin-right: 4px;
            margin-top: 5px;
            border-radius: 5px;
            text-align: center;
            width: 36px;
            height: 36px;
            transition: 0.3s;
        }

        a:hover {
            background: #3194db;
            color: #fff;
            text-decoration: none;
        }
    }

    /**
     * Las políticas son ocultadas en dispositivos de pantalla pequeños 
     */
    @media(max-width:768px) {
        .footer-policies, .footer-risks {
            display: none;
        }
    }
}
