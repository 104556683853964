a {
    -o-transition:0.75s;
    -ms-transition:0.75s;
    -moz-transition:0.75s;
    -webkit-transition:0.75s;

    transition:0.75s;

    &:focus-visible {
        outline: none;
    }

    &:hover:not(.btn) {
        color: rgb(204, 33, 133) !important;
        text-decoration: none;
    }
}